import {JwtAxios} from "@/packaging";

export const noticeAPI = {
    async GetNoticeList(keyword, page){
        const responseModel = await JwtAxios.get(`/Notice/GetNoticeList?siteType=0&keyword=${keyword}&page=${page}`);
        return responseModel
    },
    async GetNoticeDetail(no){
        const responseModel = await JwtAxios.get(`/Notice/GetNoticeDetail?siteType=0&no=${no}`);
        return responseModel
    },
    async GetNoticeListTotalCount(){
        const responseModel = await JwtAxios.get(`/Notice/GetNoticeListTotalCount`);
        return responseModel
    },
}