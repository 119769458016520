import Vue from 'vue'
import VueRouter from 'vue-router'
import Applayout from "@/layout/app";
import { useAuthStore } from '@/stores';

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        component: Applayout,
        children: [
            {
                path: "/",
                name: "메인",
                component: () => import("./views/app/index"),
                meta: { auth: false, title: '메인화면',subTitle:'' }
            },
            {
                path: "database/:type",
                name: "database",
                component: () => import("./views/app/database"),
                meta: { auth: false, title: 'database',subTitle:'' }
            },
            {
                path: "faq",
                name: "faq",
                component: () => import("./views/app/faq"),
                meta: { auth: false, title: 'faq',subTitle:'' }
            },
            {
                path: "license",
                name: "license",
                component: () => import("./views/app/license_back"),
                meta: { auth: false, title: 'license',subTitle:'' }
            },
            {
                path: "mypage",
                name: "mypage",
                component: () => import("./views/app/myPage"),
                meta: { auth: true, title: 'mypage',subTitle:'' }
            },
            {
                path: "mydelete",
                name: "mydelete",
                component: () => import("./views/app/myDelete"),
                meta: { auth: true, title: 'mydelete',subTitle:'' }
            },
            {
                path: "myupdate",
                name: "myupdate",
                component: () => import("./views/app/myUpdate"),
                meta: { auth: true, title: 'myUpdate',subTitle:'' }
            },
            {
                path: "notice",
                name: "notice",
                component: () => import("./views/app/notice"),
                meta: { auth: false, title: 'notice',subTitle:'' }
            },
            {
                path: "noticeDetail",
                name: "noticeDetail",
                component: () => import("./views/app/noticeDetail"),
                meta: { auth: false, title: 'noticeDetail',subTitle:'' }
            },
            {
                path: "signup",
                name: "signup",
                component: () => import("./views/app/signup"),
                meta: { auth: false, title: 'signup',subTitle:'' },
            },
            {
                path: "lostAccount",
                name: "lostAccount",
                component: () => import("./views/app/lostAccount"),
                meta: { auth: false, title: 'lostAccount',subTitle:'' },
            },
            {
                path: "logout",
                name: "logout",
                component: () => import("./views/app/logout"),
                meta: { auth: true, title: 'logout',subTitle:'' },
            },
            {
                path: "portal",
                name: "portal",
                component: () => import("./views/app/portal"),
                meta: { auth: false, title: 'portal',subTitle:'' },
            },
            {
                path: "permitting",
                name: "permitting",
                component: () => import("./views/app/permitting"),
                meta: { auth: false, title: 'permitting',subTitle:'' },
            },
            {
                path: "corporation",
                name: "corporation",
                component: () => import("./views/app/corporation"),
                meta: { auth: false, title: 'corporation',subTitle:'' },
            },
            {
                path: "market",
                name: "market",
                component: () => import("./views/app/market"),
                meta: { auth: false, title: 'market',subTitle:'' },
            },
            {
                path: "phone",
                name: "phone",
                component: () => import("./views/app/phone"),
                meta: { auth: false, title: 'phone',subTitle:'' },
            },
            {
                path: "fax",
                name: "fax",
                component: () => import("./views/app/fax"),
                meta: { auth: false, title: 'fax',subTitle:'' },
            },
            {
                path: "email",
                name: "email",
                component: () => import("./views/app/email"),
                meta: { auth: false, title: 'email',subTitle:'' },
            },
            {
                path: "corp",
                name: "corp",
                component: () => import("./views/app/corp"),
                meta: { auth: false, title: 'corp',subTitle:'' },
            },
            {
                path: "company",
                name: "company",
                component: () => import("./views/app/company"),
                meta: { auth: false, title: 'company',subTitle:'' }
            },
            {
                path: "term1",
                name: "term1",
                component: () => import("./views/app/term1"),
                meta: { auth: false, title: 'term1',subTitle:'' }
            },
            {
                path: "term2",
                name: "term2",
                component: () => import("./views/app/term2"),
                meta: { auth: false, title: 'term2',subTitle:'' }
            },
            {
                path: "database/:type",
                name: "database",
                component: () => import("./views/app/database"),
                meta: { auth: false, title: 'database',subTitle:'' }
            },
            {
                path: "pgResult",
                name: "pgResult",
                component: () => import("./views/app/pgResult"),
                meta: { auth: false, title: 'pgResult', subTitle: '' }
            }
        ]
    },
]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})



router.beforeEach(async (to,from, next) => {
    if(to.meta.auth == false || to.meta.auth ==undefined){
        next()
        return
    }
    const auth = useAuthStore();
    if (to.meta.auth == true && (auth.accessToken == null|| !auth.accessToken)) {
        next('/')
        return
    }
    return next()
});

export default router;