<template>
  <b-modal size="xl" ref="allCategoryModal"  hide-footer hide-header>
    <div class="allCategory-modal">
      <div class="title">
        <h3>전체 카테고리</h3>
        <i @click="close()">
        </i>
      </div>
      <div class="category-header">
        <div @click.prevent="categoryClick(1)" :class="{active : activeCategory == 1 }">건설/제조</div>
        <div @click.prevent="categoryClick(2)" :class="{active : activeCategory == 2 }">교육</div>
        <div @click.prevent="categoryClick(3)" :class="{active : activeCategory == 3 }">물류/창고/유통</div>
        <div @click.prevent="categoryClick(4)" :class="{active : activeCategory == 4 }">미용</div>
        <div @click.prevent="categoryClick(5)" :class="{active : activeCategory == 5 }">미디어</div>
        <div @click.prevent="categoryClick(6)" :class="{active : activeCategory == 6 }">병원/건강</div>
        <div @click.prevent="categoryClick(7)" :class="{active : activeCategory == 7 }">부동산</div>
        <div @click.prevent="categoryClick(8)" :class="{active : activeCategory == 8 }">쇼핑/유통</div>
        <div @click.prevent="categoryClick(9)" :class="{active : activeCategory == 9 }">숙박업</div>
        <div @click.prevent="categoryClick(10)" :class="{active : activeCategory == 10 }">식/음료</div>
        <div @click.prevent="categoryClick(11)" :class="{active : activeCategory == 11 }">생활/편의</div>
        <div @click.prevent="categoryClick(12)" :class="{active : activeCategory == 12 }">서비스</div>
        <div @click.prevent="categoryClick(13)" :class="{active : activeCategory == 13 }">종교</div>
        <div @click.prevent="categoryClick(14)" :class="{active : activeCategory == 14 }">유흥</div>
      </div>
      <div class="allCategory-box custom-scrollbar">
        <div class="category-section" id="cate1">
          <h3>건설/제조</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['1']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" tabindex="0" id="cate2">
          <h3>교육</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['2']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate3">
          <h3>물류/창고/유통</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['3']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate4">
          <h3>미용</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['4']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate5">
          <h3>미디어</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['5']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate6">
          <h3>병원/건강</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['6']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate7">
          <h3>부동산</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['7']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate8">
          <h3>쇼핑/유통</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['8']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate9">
          <h3>숙박업</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['9']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate10">
          <h3>식/음료</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['10']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate11">
          <h3>생활/편의</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['11']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate12">
          <h3>서비스</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['12']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate13">
          <h3>종교</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['13']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
        <div class="category-section" id="cate14">
          <h3>유흥</h3>
          <div class="category-items">
            <div v-for="(item) in categoryData['14']" :key="item"><router-link :to="`/database/${item.includes('/') ? item.replaceAll('/', '%2F') : item }`">{{ item }}</router-link></div>
          </div>
        </div>
      </div>

    </div>
  </b-modal>
</template>

<script>

export default {
  name: "allCategory",

  data(){
    return {
      isCheck: true,
      allCategoryModal:{
        account:'',
        password:'',
        isSave:false
      },
      activeCategory : 1,
      categoryData : {
        1 : [
            "제조","건설","공사","인테리어","간판","조경","페인트/도장","현수막","지게차","건축업","건축사무소","의류제작","인력사무소","샷시","철거","폐기물","금속제조","사출","석재"
        ],
        2 : [
            "영,유아교육","초등학교","중학교","고등학교","대학교","학원,교습,운동,음악","학습지"
        ],
        3 : [
            "물류", "창고", "유통"
        ],
        4 : [
            "미용실", "피부과", "네일아트", "피부,체형관리", "마사지", "다이어트","왁싱,제모"
        ],
        5 : [
            "엔터테인먼트", "콘텐츠제작","방송,영상","게임제작","마케팅"
        ],
        6 : [
            "병원","한의원","성형외과","산후조리원","요양원","약국","장례/화장/납골당","건강기능식품","치과용품"
        ],
        7 : [
            "공인중개사", "시행사","건물관리","이사업체","아파트","아파트 관리실"
        ],
        8 : [
            "슈퍼,마트","아울렛", "백화점","복권,로또","금은방","중고거래","중고차","오토바이","정육점","주유소","철물점","출판사","보안,CCTV","가구","휴대폰대리점","의류,패션","공방","도사,문구,사무용품","반려동물","화장품","자동차용품","캠핑용품","낚시용품","과일"
        ],
        9 : [
            "호텔","모텔","펜션","리조트","게스트하우스","고시원,고시텔","캠핑장,야영장","캠핑카","연수원,수련원"
        ],
        10 : [
            "음식점","술집","카페/디저트","키즈카페","애견카페","프렌차이즈","반찬가게"
        ],
        11 : [
            "찜질방", "사우나","안경점","꽃집","세탁/빨래방","카센터,정비","렌터카","농장","사진관,스튜디오","인쇄소,복사,제본","에어컨","은행","보험","경로당","경호","환전소","세차장","재활용"
        ],
        12 : [
            "법률사무소", "법무사","세무사무소","행정사사무소","관공서,공공기관","협회,단체","협동조합","결혼/웨딩","철학관","컴퓨터,컴퓨터수리","사회/복지","배달대행","청소","소프트웨어개발","심부름센터/흥신소"
        ],
        13 : [
            "교회", "성당/천주교","절/불교"
        ],
        14 : [
            "노래방", "단란주점","유흥주점","나이트클럽","PC방"
        ]
      },
    }
  },
  methods:{
    open(){
      this.$refs.allCategoryModal.show()
    },
    close(){
      this.$refs.allCategoryModal.hide()
    },
    categoryClick(num){
      this.activeCategory = num;
      const section = document.getElementById('cate'+ num);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

}
</script>

<style scoped>
.form-control[readonly] {
  background: white;
}
</style>