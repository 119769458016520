<template>
  <div v-if="showPopup" class="kakaoPopup">
    <div class="kakaoSubTitle">
      <div class="kakaoClose" style="cursor:pointer;" @click="KakaoPopupClose24"></div>
      <p class="kakaoName">DB구매 문의</p>
      <p class="kakaoPhone">0508-4265-4300</p>
      <span style="font-size:14px">평일: 09:00~18:00<br>토 / 일요일: 휴무<br>(카톡 플친 문의만 가능)</span>
    </div>
    <div class="popup-footer">
      <div class="kakao-inquiry">
        <a href="javascript:;" @click="GetKakaoChat">카카오톡 문의</a>
      </div>
      <div class="license">
        <router-link to="/license">
          라이센스 구매
        </router-link>
      </div>
    </div>
  </div>
  <div v-else class="kakaoPopup2">
      <img @click="showPopup=true" src="/assets/images/small_kakao.png"/>
  </div>
</template>

<script>
export default {
  name: "KakaoPopup",
  mounted() {
    this.checkPopupState();
  },
  data(){
    return {
      showPopup: true
    }
  },
  methods:{
    GetKakaoChat(){
      window.open('http://pf.kakao.com/_JVJNb/chat', 'kakaoChannel', 'width=471, height=640')
    },
    getTodayDate() {
      const today = new Date();
      return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    },
    checkPopupState() {
      const storedDate = localStorage.getItem('popupDate');
      if (storedDate === this.getTodayDate()) {
        this.showPopup = false;
      }
    },
    KakaoPopupClose24() {
      this.showPopup = false
      localStorage.setItem('popupDate', this.getTodayDate());
      this.closePopup();
    }
  }
}
</script>

<style scoped>

.kakaoPopup{
  position:fixed;
  z-index:10000;
  bottom:40px;
  right:10px;
  background-color: #222222C9;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  width: 250px;
  padding: 15px 15px 20px;
  display: none; /* 기본은 숨김 */
}
.kakaoPopup2{
  position:fixed;
  z-index:10000;
  bottom:20px;
  right:20px;
  cursor: pointer;
  display: none; /* 기본은 숨김 */
}



.kakaoPopup .kakaoClose {
  position: absolute;
  right: 8px;
  top: 14px;
  width: 20px;
  height: 20px;

}

.kakaoPopup .kakaoClose:hover {

}

.kakaoPopup .kakaoClose:before, .kakaoPopup .kakaoClose:after {
  position: absolute;
  right: 15px;
  content: ' ';
  height: 26px;
  width: 2px;
  background-color: #fff;
}
.kakaoPopup .kakaoClose:before {
  transform: rotate(45deg);
}
.kakaoPopup .kakaoClose:after {
  transform: rotate(-45deg);
}

.kakaoPopup  .kakaoSubTitle{

  padding: 22px 3px 5px;
  border-radius: 10px;

  margin-bottom: 5px;
  text-align: center;
  color:black;
}
.kakaoPopup .kakaoName {
  font-size:16px;
  margin:0px 0px 3px;
  color:#fff;
}
.kakaoPopup .kakaoPhone {
  font-size:25px;
  font-weight:bold;
  color: #fff;
  margin:0px 0px 3px;
  line-height: 33px;
  letter-spacing: -0.3px;
}
.kakaoPopup .kakaoSubTitle span {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.3px;
  color:#fff;
}
.kakaoPopup .popup-footer {
  display:flex;
  gap:5px;
}
.kakaoPopup .kakao-inquiry,
.kakaoPopup .license {
  flex: 1;
  text-align: center;
  font-size: 14px;
  padding: 13px 0;
  border-radius: 10px;
  cursor:pointer;
}
.kakaoPopup .kakao-inquiry {
  background-color:#f9e000;
}
.kakaoPopup .kakao-inquiry:hover {
  background-color:#f9c600;
}
.kakaoPopup .kakao-inquiry > a {
  color:#222;
  font-weight: 700;
}
.kakaoPopup .license {
  background-color:#0069C1;
}
.kakaoPopup .license:hover {
  background-color:#0059A3;
}
.kakaoPopup .license > a {
  color:#fff;
  font-width: 700;
}
@media (min-width: 1800px) {
  .kakaoPopup {
    display: block;
  }
  .kakaoPopup2 {
    display: block;
  }
}
</style>