import { defineStore } from 'pinia';
import router from "@/router";
import {accountAPI} from "@/api";

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        user: null,
    }),
    actions: {
        async setupUser(){
            let response = await accountAPI.GetUser()
            if(response.success == true){
                this.user = response.data
            }
            return this.user
        },

        async login(account, password, isSave) {
            const responseModel = await accountAPI.Login({
                "siteType":0,
                "account": account,
                "password": password
            })
            if(responseModel.success == true) {
                this.user = responseModel.data
                this.accessToken = responseModel.data.accessToken;
                this.refreshToken = responseModel.data.refreshToken;
                if (isSave == true) localStorage.setItem('saveId', account);
                localStorage.setItem('accessToken', this.accessToken);
                localStorage.setItem('refreshToken', this.refreshToken);
            }else{
                localStorage.removeItem('saveId');
            }
            return responseModel;
        },


        logout() {
            this.accessToken = null;
            this.refreshToken = null;
            this.user = null;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
    }
})