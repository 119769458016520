<template>
  <!--  <nav class="navbar navbar-expand-lg navbar-light nav-sec">-->
  <!--    <div class="container">-->
  <!--      <router-link to="/">-->
  <!--        <a class="navbar-brand" href="#">-->
  <!--          <img src="/assets/images/logo.png" style="cursor: pointer" alt="">-->
  <!--        </a>-->
  <!--      </router-link>-->
  <!--      <button class="navbar-toggler" v-b-toggle.navbarSupportedContent>-->
  <!--        <span class="navbar-toggler-icon"></span>-->
  <!--      </button>-->
  <!--      <b-collapse class="navbar-collapse" id="navbarSupportedContent">-->
  <!--        <ul class="navbar-nav">-->
  <!--          <li v-if="isLogin == false" class="nav-item">-->
  <!--            <a href="#" class="nav-link" :class="{'active' : isLogin == false}" @click="loginModalOpen">로그인</a>-->
  <!--          </li>-->
  <!--          <li v-else class="nav-item">-->
  <!--            <a href="#" class="nav-link" :class="{'active' : isLogin == true}" @click="logout">로그아웃</a>-->
  <!--          </li>-->
  <!--          <li v-if="isLogin == false" class="nav-item">-->
  <!--            <router-link to="/signup" class="nav-link">-->
  <!--              회원가입-->
  <!--            </router-link>-->
  <!--          </li>-->
  <!--          <li v-else class="nav-item">-->
  <!--            <router-link to="/mypage" class="nav-link">-->
  <!--              마이페이지-->
  <!--            </router-link>-->
  <!--          </li>-->
  <!--          <li class="nav-item">-->
  <!--            <router-link to="/notice" class="nav-link">-->
  <!--              고객센터-->
  <!--            </router-link>-->
  <!--          </li>-->
  <!--          <li class="nav-item">-->
  <!--            <router-link to="/license" class="nav-link">-->
  <!--              이용권 구매-->
  <!--            </router-link>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </b-collapse >-->
  <!--    </div>-->
  <!--    <loginModal :loginInit="loginInit" ref="loginModal"/>-->
  <!--  </nav>-->
  <nav class="navbar  navbar-expand-lg navbar-light nav-sec">
    <div class="container">
      <div class="nav-logo navbar-brand">
        <router-link to="/"><img src="/assets/images/logo.png" style="cursor: pointer" alt=""></router-link>
      </div>
      <div class="navbar-toggler-box">
        <div class="user-before">
          <ul class="nav-user ">
            <li v-if="isLogin == false" class="nav-item">
              <a href="#" class="nav-link" :class="{'active' : isLogin == false}" @click="loginModalOpen">로그인</a>
            </li>
            <li v-else class="nav-item">
              <a href="#" class="nav-link" :class="{'active' : isLogin == true}" @click="logout">로그아웃</a>
            </li>
            <li v-if="isLogin == false" class="nav-item">
              <router-link to="/signup" class="nav-link">
                회원가입
              </router-link>
            </li>
            <li v-else class="nav-item">
              <router-link to="/mypage" class="nav-link">
                마이페이지
              </router-link>
            </li>
          </ul>
        </div>

        <button class="navbar-toggler" v-b-toggle.navbarSupportedContent @click="handleNavbarToggle">
          <span class="navbar-toggler-icon"></span>
        </button>
        <b-collapse class="navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/portal" class="nav-link">
                포털DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/permitting" class="nav-link">
                인허가DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link">
                기업DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/market" class="nav-link">
                마켓DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/corporation" class="nav-link">
                법인DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/phone" class="nav-link">
                휴대폰DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/email" class="nav-link">
                이메일DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/fax" class="nav-link">
                팩스DB
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/notice" class="nav-link">
                고객센터
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/license" class="nav-link nav-license">
                이용권 구매
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <div class="nav-dim" @click="toggle"></div>
      </div>
      <ul class="nav-user user-after">
        <li v-if="isLogin == false" class="nav-item">
          <a href="#" class="nav-link" :class="{'active' : isLogin == false}" @click="loginModalOpen">로그인</a>
        </li>
        <li v-else class="nav-item">
          <a href="#" class="nav-link" :class="{'active' : isLogin == true}" @click="logout">로그아웃</a>
        </li>
        <li v-if="isLogin == false" class="nav-item">
          <router-link to="/signup" class="nav-link">
            회원가입
          </router-link>
        </li>
        <li v-else class="nav-item">
          <router-link to="/mypage" class="nav-link">
            마이페이지
          </router-link>
        </li>
      </ul>
    </div>
    <loginModal :loginInit="loginInit" ref="loginModal"/>
  </nav>
</template>

<script>
import loginModal from './Modals/LoginModal'

export default {
  name: "Hearder",
  components: {
    loginModal
  },
  data() {
    return {
      isLogin: false,
      loginModel: null
    }
  },
  async created() {
    await this.loginInit()
  },
  watch: {
    async user(data) {
      if (data == false) {
        this.isLogin = false
      }
    }
  },
  methods: {
    logout() {
      this.authStore.logout()
      this.isLogin = false
    },
    async loginInit() {
      var setupUserModel = await this.authStore.setupUser()
      if (setupUserModel != undefined && setupUserModel != null) {
        this.isLogin = true
        this.loginModel = setupUserModel
      }
    },
    loginModalOpen() {
      this.$refs.loginModal.open()
    },
    handleNavbarToggle(){
      let check = document.getElementById("navbarSupportedContent").hasAttribute("class", "show");
      console.log(check);
      if (check == false){

      }
    },
    toggle(){
      document.getElementsByClassName("navbar-toggler")[0].click();
    }
  }
}
</script>

<style scoped>
.small-font {
  font-size: 13px;
}

.normal-font {
  font-size: 16px;
}
</style>