import {JwtAxios} from "@/packaging";

export const smsAPI = {
    async SendSMS(token){
        const responseModel = await JwtAxios.get(`/Sms/SendSMS?token=${token}`);
        return responseModel
    },

    async SetToken(phone){
        const responseModel = await JwtAxios.post(`/Sms/SetToken?siteType=0&phoneNumber=${phone}`,'');
        return responseModel
    },
    async SetUserToken(name, phone){
        const responseModel = await JwtAxios.post(`/Sms/SetUserToken?siteType=0&name=${name}&phoneNumber=${phone}`,'');
        return responseModel
    },
    async CheckSMS(token,tokenValue){
        const responseModel = await JwtAxios.get(`/Sms/CheckSMS?siteType=0&token=${token}&tokenValue=${tokenValue}`);
        return responseModel
    },
}