<template>
  <div>
    <section class="footer-term-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="term-col">
              <router-link to="/term1">서비스 이용약관</router-link>
              <router-link to="/term2">개인정보처리방침</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="representative-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="representative-col">
              <div class="representative-img">
                <img src="/assets/images/footer-logo.png" alt="">
              </div>
              <div class="representative-content">
                <h6>
                  대표 김태민　|　사업자번호 517-22-051566　|　통신판매업신고번호 2022-서울관악-0935
                  <br>
                  개인정보책임자 김태민　|　서울특별시 강서구 공항대로 242, 2층 211호
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="footer-info-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="footer-info-col">
              <div class="footer-info-content">
                <h6>
                  ※ 본 서비스에서 제공하는 자료에 대해 당사의 승인을 서면으로 득하지 않은 무단전제와 재배포, 일체의 재생산 및 지적재산권 침해행위를 금지합니다.
                  <br>
                  ※ 해당 서비스는 불법적인 용도로 사용 불가하며, 불법적인 용도로 사용하는 경우, 그 책임은 전적으로 사용자에게 있습니다.
                  <br>
                  ※ 광고성 수신 동의를 받지 않은 DB에 "광고성 홍보 활동"은 금지되어 있으며, 해당 책임은 사용자에게 있습니다.
                  <br>
                  ※ 본 서비스를 영리성 광고 목적으로 활용하는 경우 정보이용촉진 및 정보보호등에 관한 법률 제50조에 따른 준수사항을 반드시 지켜야 하며, 이를 어길 경우 관련법에 따라 처벌받을 수 있습니다.
                  <br>
                  ※ 데이터관련 상품은 아래와 같은 내용을 근거로 환불이 불가합니다.
                  <br>
                  "상담(컨설팅)이 완료된 이후 또는 자료, 프로그램 등 서비스가 제공된 이후에는 환불이 불가합니다.
                  <br>
                  (소비자보호법 17조 2항의 5조. 용역 또는 「문화산업진흥 기본법」 제2조제5호의 디지털콘텐츠의 제공이 개시된 경우에 해당)"
                  <br>
                  ※ 반드시 샘플데이터를 미리 확인해 주시기 바랍니다.
                </h6>
                <p>
                  Copyright © 2024 DB매니저 | Powered by DB매니저 | Designed by Icons 8 from Ouch!​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>