<template>
  <div>
    <section class="hero-sec">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="hero-content">
              <p>실제 신규 사업자 DB를 <b>합리적인 가격</b>으로 <b>무제한</b> 제공!</p>
              <h1>상위 1% 영업사원들의 돈버는 비밀</h1>
              <h6>높은 계약율을 유지하는 최신디비</h6>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="hero-img">
              <img src="/assets/images/hero-img.gif">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="econ-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="econ-col">
              <div class="econ-inner">
                <router-link to="/portal">
                    <div class="econ-content">
                      <img src="/assets/images/econimg1.png" alt="">
                      <h6>포털DB</h6>
                      <p>Total <b>
                        <ICountUp
                          :delay="delay"
                          :endVal="summaryModel.filter(x => x.type == 'portal')[0]?.total"
                          :options="options"
                          @ready="onReady"
                          class="countBase"
                      >
                      </ICountUp></b>건<br>Today <b>
                        <ICountUp
                          :delay="delay"
                          :endVal="summaryModel.filter(x => x.type == 'portal')[0]?.today"
                          :options="options"
                          @ready="onReady"
                          class="countBase"
                      >
                      </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="./permitting">
                    <div class="econ-content">
                      <img src="/assets/images/econimg2.png" alt="">
                      <h6>인허가DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'permitting')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                          :delay="delay"
                          :endVal="summaryModel.filter(x => x.type == 'permitting')[0]?.today"
                          :options="options"
                          @ready="onReady"
                          class="countBase"
                      >
                      </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/company">
                    <div class="econ-content">
                      <img src="/assets/images/econimg3.png" alt="">
                      <h6>기업DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'company')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'company')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/corporation">
                    <div class="econ-content">
                      <img src="/assets/images/econimg4.png" alt="">
                      <h6>법인DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'corp')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'corp')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/market">
                    <div class="econ-content">
                      <img src="/assets/images/econimg5.png" alt="">
                      <h6>마켓DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'market')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'market')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/phone">
                    <div class="econ-content">
                      <img src="/assets/images/econimg6.png" alt="">
                      <h6>휴대폰DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'phone')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'phone')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/email">
                    <div class="econ-content">
                      <img src="/assets/images/econimg7.png" alt="">
                      <h6>이메일DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'email')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'email')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
              <div class="econ-inner">
                <router-link to="/fax">
                    <div class="econ-content">
                      <img src="/assets/images/econimg8.png" alt="">
                      <h6>팩스DB</h6>
                      <p>Total <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'fax')[0]?.total"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp>
                      </b>건<br>Today <b>
                        <ICountUp
                            :delay="delay"
                            :endVal="summaryModel.filter(x => x.type == 'fax')[0]?.today"
                            :options="options"
                            @ready="onReady"
                            class="countBase"
                        >
                        </ICountUp></b>건</p>
                    </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="collect-sec">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="collect-col">
              <div class="collect-inner">
                <p>
                  포털 DB
                </p>
                <h6>
                  <img src="/assets/images/collectimg1.gif" alt="">
                  수집중
                </h6>
              </div>
              <div class="collect-content">
                <h5>{{$dayjs().format('MM월 DD일')}} UPDATE</h5>
                <p>각 종 DB 실시간 수집 중!</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="collect-frame-col">
              <div class="collect-frame-inner">
                <h4>
                  <ICountUp
                      :delay="delay"
                      :endVal="summaryModel.filter(x => x.type == 'total')[0]?.total"
                      :options="options"
                      @ready="onReady"
                      class="countBase"
                  >
                  </ICountUp>
                </h4>
                <span>
								전체디비
								<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"
                     fill="none">
									<circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
									<path
                      d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                      fill="white"></path>
								</svg>
							</span>
              </div>
              <div class="collect-frame-inner">
                <h4>
                  <ICountUp
                      :delay="delay"
                      :endVal="summaryModel.filter(x => x.type == 'total')[0]?.today"
                      :options="options"
                      @ready="onReady"
                      class="countBase"
                  >
                  </ICountUp>
                </h4>
                <span>
								오늘신규
								<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"
                     fill="none">
									<circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
									<path
                      d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                      fill="white"></path>
								</svg>
							</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="industry-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="industry-heading">
						<span>
							<h6>BEST 인기업종</h6>
							<p>영업사원들이 가장 많이 찾는 분야별 인기 카테고리</p>
						</span>
            </div>
          </div>
          <div class="category-box">
            <div class="category-slick-box" style="width:calc(100% - 6.625rem - 30px);">
              <slick ref="slick" :options="slickOptions">
                <router-link :to="`/database/제조`"><img src="/assets/images/in-slick-img11.png" alt=""><p>건설/제조</p></router-link>
                <router-link :to="`/database/교육`"><img src="/assets/images/in-slick-img1.png" alt=""><p>교육/학원</p></router-link>
                <router-link :to="`/database/물류`"><img src="/assets/images/in-slick-img2.png" alt=""><p>물류/창고</p></router-link>
                <router-link :to="`/database/병원`"><img src="/assets/images/in-slick-img3.png" alt=""><p>병원/건강</p></router-link>
                <router-link :to="`/database/부동산`"><img src="/assets/images/in-slick-img4.png" alt=""><p>부동산</p></router-link>
                <router-link :to="`/database/생활`"><img src="/assets/images/in-slick-img5.png" alt=""><p>생활/편의</p></router-link>
                <router-link :to="`/database/쇼핑`"><img src="/assets/images/in-slick-img6.png" alt=""><p>쇼핑/유통</p></router-link>
                <router-link :to="`/database/숙박`"><img src="/assets/images/in-slick-img7.png" alt=""><p>숙박업</p></router-link>
                <router-link :to="`/database/유흥`"><img src="/assets/images/in-slick-img8.png" alt=""><p>유흥</p></router-link>
                <router-link :to="`/database/음식점`"><img src="/assets/images/in-slick-img9.png" alt=""><p>음식점</p></router-link>
                <router-link :to="`/database/종교`"><img src="/assets/images/in-slick-img10.png" alt=""><p>종교</p></router-link>
              </slick>
            </div>
            <div class="total-category-box">
              <div href="#" tabindex="0" @click="allCategoryModalOpen">
                <p>전체</p>
                <p>전체보기</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>포털DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'portal')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'portal')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('portalTable','포털DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/portal" class="arrow-btn">
                포털 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="portalTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">연락처</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.portal" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="5"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec2x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>인허가DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'permitting')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'permitting')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('permittingTable','인허가DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/permitting" class="arrow-btn">
                인허가 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="permittingTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">연락처</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.license" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="5"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec2x extraction-sec3x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>기업DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'company')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'company')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('companyTable','기업DB_샘플')">
                <img src="assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/company" class="arrow-btn">
                기업 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id='companyTable' class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">업종</th>
                  <th scope="col">규모</th>
                  <th scope="col">매출</th>
                  <th scope="col">직원수</th>
                  <th scope="col">주소</th>
                  <th scope="col">연락처</th>
                  <th scope="col">팩스</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.company" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.className }}</td>
                  <td>{{ item.totalPrice }}</td>
                  <td>{{ item.userCount }}</td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.fax }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="9"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec2x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>마켓DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'market')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'market')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('marketTable','마켓DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/market" class="arrow-btn">
                마켓 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table m-t" v-if="isSummaryDataLoading == false">
              <table id="marketTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">대표자명</th>
                  <th scope="col">연락처</th>
                  <th scope="col">이메일</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                  <th scope="col">샵링크</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.market" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                  <td><a href="" @click="GoUrl(item.link, $event)"><img src="/assets/images/link_btn.png"></a></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="8"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec2x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>법인DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'corp')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'corp')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('corpTable','법인DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/corp" class="arrow-btn">
                법인 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="corpTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">연락처</th>
                  <th scope="col">팩스</th>
                  <th scope="col">이메일</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.corp" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.fax }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="7"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec2x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>휴대폰DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'phone')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'phone')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('phoneTable','휴대폰DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/phone" class="arrow-btn">
                휴대폰 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="phoneTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">연락처</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.phone" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="5"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec4x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>이메일DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'email')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'email')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('emailTable','이메일DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/email" class="arrow-btn">
                이메일 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="emailTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">이메일</th>
                  <th scope="col">연락처</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.email" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extraction-sec extraction-sec4x">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="extraction-col1x">
						<span>
							<h4>팩스DB</h4>
							<p>등록업체:<b class="c-total-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'fax')[0]?.total"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp></b></p>
							<p>Today 업데이트:<b class="c-today-cnt"> <ICountUp
                  :delay="delay"
                  :endVal="summaryModel.filter(x => x.type == 'fax')[0]?.today"
                  :options="options"
                  @ready="onReady"
                  class="countBase"
              ></ICountUp>건</b></p>
						</span>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="extraction-col2x">
              <button class="excel-btn" type="button" @click="exportToExcel('faxTable','팩스DB_샘플')">
                <img src="/assets/images/excel.svg" alt="">
                샘플 다운로드
              </button>
              <router-link to="/fax" class="arrow-btn">
                팩스 DB 더보기
                <img src="/assets/images/right-arrow1x.svg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="extraction-table" v-if="isSummaryDataLoading == false">
              <table id="faxTable" class="table">
                <thead>
                <tr>
                  <th scope="col">DB추출일</th>
                  <th scope="col">업체명</th>
                  <th scope="col">팩스번호</th>
                  <th scope="col">연락처</th>
                  <th scope="col">업종</th>
                  <th scope="col">주소</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in summaryData.fax" :key = index>
                  <td>
                    <span>
											  <svg  v-if="$dayjs().isSame($dayjs(item.createDate), 'day')"  xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                              viewBox="0 0 100 100" fill="none">
                          <circle cx="50" cy="50" r="50" fill="#D32A2A"></circle>
                          <path
                              d="M68.375 71H59.0586L43.2969 48.0898H43.0039V71H31.8711V28.5781H41.3633L56.8906 51.3711H57.2422V28.5781H68.375V71Z"
                              fill="white"></path>
                        </svg>
                        {{ $dayjs().diff(item.createDate, "hours") > 24 ? $dayjs(item.createDate).format("YYYY-MM-DD") : $dayjs(item.createDate).fromNow() }}
										  </span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.fax }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.depth }}</td>
                  <td>{{ item.address }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="extraction-table" v-else>
              <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="announce-ques-sec">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <router-link to="/notice">
              <div class="announce-ques-col1x">
                <img src="/assets/images/announce-ques-img1.png" alt="">
                <span>
                  <a class="router-link-exact-active active">공지사항</a>
                <p>
                  DB매니저의 공지사항을 알려드립니다. <br>
                  다양한 정보와 새로운 소식을 만나보세요.
                </p>
              </span>
              </div>
            </router-link>
          </div>
          <div class="col-12 col-lg-6">
            <router-link to="/faq">
              <div class="announce-ques-col1x announce-ques-col2x">
                <img src="/assets/images/announce-ques-img2.png" alt="">
                <span>
                <a class="router-link-exact-active active">자주하는 질문</a>
                <p>
                  이용 시 궁금한 점을 모았습니다. <br>
                  문의 전 꼭 사용해보세요.
                </p>
						  </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>


    <allCategory class="test" ref="allCategoryModal"/>
  </div>
</template>

<script>
import {oepnMarketAPI, naverPlaceAPI, dbAPI} from '@/api/index';
import ICountUp from 'vue-countup-v2';
import allCategory from './Modals/AllCategory'
import * as XLSX from 'xlsx';

export default {
  components: {
    ICountUp,
    allCategory
  },
  data() {
    return {
      summaryData :{
      },
      summaryModel:[
          {
            "type": "portal",
            "total": 100000,
            "today": 0
          },
          {
            "type": "permitting",
            "total": 100000,
            "today": 0
          },
          {
            "type": "company",
            "total": 100000,
            "today": 0
          },
          {
            "type": "corp",
            "total": 100000,
            "today": 0
          },
          {
            "type": "market",
            "total": 100000,
            "today": 0
          },
          {
            "type": "phone",
            "total": 100000,
            "today": 0
          },
          {
            "type": "email",
            "total": 100000,
            "today": 0
          },
          {
            "type": "fax",
            "total": 100000,
            "today": 0
          },
          {
            "type": "total",
            "total": 100000,
            "today": 0
          }
      ],
      isSummaryLoading:false,
      isSummaryDataLoading:false,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      delay: 1000,
      isOpenMarketLoding: false,
      openMarketItemModel: [],
      openMarketItemModelCount: 0,
      openMarketItemTotalCount: {
        monthday: 0,
        today: 0,
        total: 0,
        yesterday: 0
      },

      isNaverPlaceLoding: false,
      naverPlaceItemModel: [],
      naverPlaceItemModelCount: 0,
      naverPlaceItemTotalCount: {
        monthday: 0,
        today: 0,
        total: 0,
        yesterday: 0
      },
      slickOptions: {
        slidesToShow: 10,
        responsive: [
          {
            breakpoint: 1024, // 화면 너비가 1024px 이하일 때
            settings: {
              slidesToShow: 8,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 770, // 화면 너비가 600px 이하일 때
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 570, // 화면 너비가 600px 이하일 때
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 430, // 화면 너비가 600px 이하일 때
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 350, // 화면 너비가 600px 이하일 때
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
    }
  },
  watch: {
    async user(data) {
      await this.GetOpenMarket()
      await this.GetNaverPlace()
    }
  },
  async created() {
    this.isSummaryDataLoading = true;
    this.isSummaryLoading = true;
    await this.GetSummary()
    await this.GetSummaryData()
    this.isSummaryDataLoading = false;
    this.isSummaryLoading = false;
  },
  methods: {
    exportToExcel(tableId, fileName) {
      const table = document.getElementById(tableId);
      const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
      XLSX.writeFile(workbook, fileName+'.xlsx');
    },
    onReady: function(instance, CountUp) {
      const that = this;
      if(that.endVal>0){
      instance.update(that.endVal);
      }
    },
    async GetSummaryData() {
      this.isSummaryDataLoading = true;
      const itemResponse = await dbAPI.GetSummaryData();
      if(itemResponse.success == true){
        this.summaryData = itemResponse.data;
      }
      this.isSummaryDataLoading = false
    },
    async GetSummary() {
      this.isSummaryLoading = true;
      const itemResponse = await dbAPI.GetSummary();
      if(itemResponse.success == true){
        this.summaryModel = itemResponse.data.summaries;
      }
      this.isSummaryLoading = false
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
      }
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$refs.slick.reSlick();
    },
    allCategoryModalOpen() {
      this.$refs.allCategoryModal.open();
    },
    GoUrl(url, event){
      event.preventDefault();
      if (url == '' || url == 'htt**'){
        alert("마켓 URL을 확인할 수 없습니다.");
      }else{
        window.open(url, '_blank');
      }
    },
  }
}
</script>

<style scoped>

</style>